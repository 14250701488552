<template>
  <div
    v-if="dataInfo"
  >
    <b-modal
      v-model="modalshow"
      :title="dataInfo.titleModal"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      :busy="isBusy"
      :ok-disabled="true"
    >
      <b-overlay
        :show="isBusy"
        rounded="sm"
      >
        <div
          v-if="isShowForm"
          ref="divForm"
        >
          <b-form-group
            label="Tipo"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="formData.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="types"
            />
          </b-form-group>

          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="formData.description"
              placeholder="Detalle información del registro"
              rows="3"
            />
          </b-form-group>
          <h6
            class="text-info"
          >
            <feather-icon icon="FilePlusIcon" />
            <span class="align-middle ml-25">Adjunte archivos</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">
          <b-form-group
            label="Título archivo"
            label-for="titleEvidenceOne"
          >
            <b-form-input
              id="titleEvidenceOne"
              v-model="formData.titleEvidenceOne"
              placeholder="Ejem: ACTA-018-2023-LAE"
              type="text"
            />
          </b-form-group>
          <b-form-group
            label="Seleccione Archivo"
            label-for="fileEvidenceOne"
          >
            <b-form-file
              id="fileEvidenceOne"
              v-model="formData.fileEvidenceOne"
              placeholder="Seleccione o arrastre aquí evidencia..."
              drop-placeholder="Arrastre aquí evidencia..."
              accept=".pdf"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="button"
              @click="saveForm"
            >
              Guardar
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hideModal"
            >
              Cancelar
            </b-button>
          </div>
        </div>
        <div
          v-if="!isShowForm"
          ref="divList"
        >
          <b-button
            variant="primary"
            class="mb-2"
            @click="enableForm"
          >
            Agregar Registro
          </b-button>
          <app-timeline class="mb-2">
            <app-timeline-item
              v-for="(incidence, index) in incidences"
              :key="`linetime-incidence-${index}`"
              icon="FileTextIcon"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ incidence.type }}</h6>
                <small class="text-muted">{{ moment(incidence.registrationDate).format('DD/MM/YYYY') }}</small>
              </div>
              <p>{{ incidence.description }}</p>
              <p
                v-if="incidence.evidenceOne"
              >
                <b-img
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="20"
                  class="mr-1"
                />
                <a
                  :href="`${pathFile}${incidence.evidenceOne}`"
                  target="_blank"
                  style="color: #6e6b7b; font-size: 0.8rem"
                >
                  <span class="align-bottom">
                    {{ incidence.titleEvidenceOne }}.pdf
                  </span>
                </a>
              </p>
              <p
                v-if="incidence.evidenceTwo"
              >
                <b-img
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="20"
                  class="mr-1"
                />
                <a
                  :href="`${pathFile}${incidence.evidenceTwo}`"
                  target="_blank"
                  style="color: #6e6b7b; font-size: 0.8rem"
                >
                  <span class="align-bottom">
                    {{ incidence.titleEvidenceTwo }}.pdf
                  </span>
                </a>
              </p>
            </app-timeline-item>
          </app-timeline>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BFormGroup, BFormTextarea, BFormFile, BButton, BFormInput, BImg, BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import config from '@/services/config'
import { showToast, showError } from '@/helpers'
import vSelect from 'vue-select'
import store from '@/store'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'

export default {
  components: {
    BModal,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BFormFile,
    BButton,
    BFormInput,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    dataInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      idEmpresa: 0,
    }
  },
  setup(props, { emit }) {
    const modalshow = ref(false)
    const isBusy = ref(false)
    const { baseURL } = config
    const pathMultimedia = ref(`${baseURL}api-checkapp/api/v1/multimedia`)
    const pathFile = ref(`${baseURL}api-ppptcd/api/v1/pdf/show?filePath=/`)

    const formData = ref({})
    const incidences = ref([])
    const isShowForm = ref(false)

    const types = ref(['ORIENTACION_TUTOR_FAMILIA', 'REGISTRO_SISEVE', 'COMPROMISOS_FAMILIA_RI_IE', 'REGISTRO_DERIVACION_AUTORIZADA', 'REGISTRO_DERIVACION_NO_AUTORIZADA', 'ACTA_INTERVENCION_DIRESA', 'SEGUIMIENTO_DIRECTOR_TUTOR', 'ACTA_EVALUACION_TUTOR', 'INFORME_FINAL_TUTOR'])

    const okMdl = () => {
      console.log('entramos al okMdl')
    }

    const enableForm = () => {
      isShowForm.value = true
    }

    const getIncidences = async () => {
      isBusy.value = true
      await store.dispatch('ppptcd/INCIDENCE_FIND_ALL', {
        idStudent: props.dataInfo.idStudent,
        registrationDate: '',
        type: 'grilla',
        query: '',
        page: 1,
        limit: 1000,
        sortBy: 'idIncidence%7CDESC',
      })
        .then(response => {
          if (response) {
            incidences.value = response.items
          }
        })
      isBusy.value = false
    }

    const saveForm = async () => {
      let service = 'ppptcd/INCIDENCE_SAVE'

      const request = new FormData()
      request.append('idStudent', props.dataInfo.idStudent)
      request.append('description', formData.value.description)
      request.append('titleEvidenceOne', formData.value.titleEvidenceOne)
      request.append('type', formData.value.type)
      if (formData.value.fileEvidenceOne !== null) request.append('fileEvidenceOne', formData.value.fileEvidenceOne)

      if (props.formType === 'edit') {
        service = 'ppptcd/INCIDENCE_UPDATE'
      }
      await store.dispatch(service, request)
        .then(async () => {
          isShowForm.value = false
          isBusy.value = true
          await getIncidences()
          isBusy.value = false
          // emit('refetch-data', response)
          // emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          // emit('update:is-toggle-sidebar', false)
        })
    }

    const resetForm = () => {
      formData.value = {}
    }

    const hideModal = () => {
      resetForm()
      isShowForm.value = false
    }

    const openModal = async () => {
      isBusy.value = true
      modalshow.value = true
      await getIncidences()
      isBusy.value = false
    }

    return {
      // Customs
      modalshow,
      okMdl,
      openModal,
      isBusy,
      pathMultimedia,

      types,
      saveForm,
      hideModal,

      formData,
      incidences,
      isShowForm,
      pathFile,
      enableForm,
      moment,
    }
  },
  methods: {
    showToast,
    showError,
  },
}
</script>

<style>
.modal-header{
  align-items: center;
}
.filepond--item {
    width: calc(50% - 0.5em);
}
@media (min-width: 30em) {
    .filepond--item {
        width: calc(50% - 0.5em);
    }
}
@media (min-width: 50em) {
    .filepond--item {
        width: calc(33.33% - 0.5em);
    }
}
</style>
