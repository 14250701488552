<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="showEmailDetails"
              variant="primary"
              block
              @click="$emit('open-form', 'new')"
            >
              Agregar Caso
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
            style="background: #fcfcff;"
          >
            <h4
              class="section-label mt-1 px-2"
              style="color: #666667; font-size: 0.9rem;"
            >
              Filtros de Búsqueda
            </h4>
            <hr style="border-color: #7367f0;">
            <h6 class="section-label mt-2 mb-1 px-2">
              Instituciones Educativas
            </h6>

            <b-list-group
              class="list-group-messages"
            >
              <b-list-group-item>
                <b-form-checkbox
                  v-for="(option, index) in insitutucionesEducativas"
                  :key="`filter-eeii-${index}`"
                  v-model="idsEEII"
                  :value="option.idEducationalInstitution"
                  class="mb-1"
                  @change="applyFilter"
                >
                  <span class="align-text-bottom font-small-2">{{ option.name }}</span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>

            <h6 class="section-label mt-1 mb-1 px-2">
              Sexo
            </h6>
            <b-list-group
              class="list-group-messages"
            >
              <b-list-group-item>
                <b-form-checkbox
                  v-for="(option, index) in sexos"
                  :key="`filter-sexo-${index}`"
                  v-model="idsSexos"
                  :value="option"
                  class="mb-1"
                  @change="applyFilter"
                >
                  <span class="align-text-bottom font-small-2">{{ option }}</span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    sexos: {
      type: Array,
      required: true,
    },
    insitutucionesEducativas: {
      type: Array,
      required: true,
    },
    showEmailDetails: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const idsSexos = ref([])
    const idsEEII = ref([])
    const filters = ref({})
    filters.value.idsSexos = []
    filters.value.idsEEII = []
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const applyFilter = () => {
      filters.value.idsSexos = idsSexos.value
      filters.value.idsEEII = idsEEII.value
      emit('apply-filter', filters)
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,

      idsSexos,
      idsEEII,
      applyFilter,
    }
  },
}
</script>

<style>

</style>
