<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >

        <div>
          <h6
            class="text-info"
          >
            <feather-icon icon="AlertCircleIcon" />
            <span class="align-middle ml-25">Datos Estudiante</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="Nombre"
            label-for="firstName"
          >
            <b-form-input
              id="firstName"
              v-model="formData.firstName"
              type="text"
            />
          </b-form-group>

          <b-form-group
            label="Apellido Paterno"
            label-for="lastNameOne"
          >
            <b-form-input
              id="lastNameOne"
              v-model="formData.lastNameOne"
            />
          </b-form-group>

          <b-form-group
            label="Apellido Materno"
            label-for="lastNameTwo"
          >
            <b-form-input
              id="lastNameTwo"
              v-model="formData.lastNameTwo"
            />
          </b-form-group>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Fecha de Nacimiento"
                label-for="birthdate"
              >
                <flat-pickr
                  id="birthdate"
                  v-model="formData.birthdate"
                  class="form-control"
                  :config="configDP"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Sexo"
                label-for="sex"
              >
                <v-select
                  id="sex"
                  v-model="formData.sex"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sexos"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Teléfono"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="formData.phone"
                  type="tel"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Correo"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  type="email"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6
            class="text-info"
          >
            <feather-icon icon="MapPinIcon" />
            <span class="align-middle ml-25">Ubicación Estudiante</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="Seleccione Departamento"
            label-for="dpto"
          >
            <v-select
              v-model="dptoSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dptos"
              @input="getUbigeos('prov', dptoSel.slice(0, 2))"
            />
          </b-form-group>
          <b-form-group
            label="Seleccione Provincia"
            label-for="prov"
          >
            <v-select
              v-model="provSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="provs"
              @input="getUbigeos('dist', provSel.slice(0, 4))"
            />
          </b-form-group>
          <b-form-group
            label="Seleccione Distrito"
            label-for="dist"
          >
            <v-select
              v-model="distSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dists"
            />
          </b-form-group>

          <b-form-group
            label="Dirección"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="formData.address"
            />
          </b-form-group>

          <h6
            class="text-info"
          >
            <feather-icon icon="UsersIcon" />
            <span class="align-middle ml-25">Datos Apoderado</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="DNI"
            label-for="dniAttorney"
          >
            <b-form-input
              id="dniAttorney"
              v-model="formData.dniAttorney"
              type="text"
            />
          </b-form-group>

          <b-form-group
            label="Nombre"
            label-for="fistNameAttorney"
          >
            <b-form-input
              id="fistNameAttorney"
              v-model="formData.fistNameAttorney"
              type="text"
            />
          </b-form-group>

          <b-form-group
            label="Apellido Paterno"
            label-for="lastNameOneAttorney"
          >
            <b-form-input
              id="lastNameOneAttorney"
              v-model="formData.lastNameOneAttorney"
            />
          </b-form-group>

          <b-form-group
            label="Apellido Materno"
            label-for="lastNameTwoAttorney"
          >
            <b-form-input
              id="lastNameTwoAttorney"
              v-model="formData.lastNameTwoAttorney"
            />
          </b-form-group>

          <b-form-group
            label="Teléfono"
            label-for="phoneAttorney"
          >
            <b-form-input
              id="phoneAttorney"
              v-model="formData.phoneAttorney"
              type="tel"
            />
          </b-form-group>

          <b-form-group
            label="Correo"
            label-for="emailAttorney"
          >
            <b-form-input
              id="emailAttorney"
              v-model="formData.emailAttorney"
              type="email"
            />
          </b-form-group>

          <h6
            class="text-info"
          >
            <feather-icon icon="BookIcon" />
            <span class="align-middle ml-25">Datos Académicos</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="Institución Educativa"
            label-for="educationalInstitution"
          >
            <v-select
              id="educationalInstitution"
              v-model="educationalInstitutionSel"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="educationalInstitutions"
              @input="loadEducationalInstitutionDirectory()"
            />
          </b-form-group>

          <b-form-group
            label="Tutor"
            label-for="tutor"
          >
            <v-select
              v-model="directorySel"
              label="firstName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="directory"
            />
          </b-form-group>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Grado"
                label-for="degree"
              >
                <v-select
                  id="degree"
                  v-model="formData.degree"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="gradosSecundaria"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Sección"
                label-for="section"
              >
                <v-select
                  id="section"
                  v-model="formData.section"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="secciones"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6
            class="text-info"
          >
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-25">Datos del Caso</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="Nivel de Riesgo"
            label-for="stateEvolution"
          >
            <v-select
              id="stateEvolution"
              v-model="formData.stateEvolution"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="stages"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="saveForm"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const isBusy = ref(false)
    const formData = ref({})
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const sexos = ref(['MASCULINO', 'FEMENINO'])
    const gradosSecundaria = ref(['1°', '2°', '3°', '4°', '5°'])
    const secciones = ref(['A', 'B', 'C', 'D', 'E', 'F', 'G'])
    const educationalInstitutions = ref([])
    const educationalInstitutionSel = ref(null)
    const directory = ref([])
    const directorySel = ref(null)
    const stages = ref(['ALTO', 'MEDIO', 'BAJO'])

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const resetForm = () => {
      formData.value = {}
    }

    const loadEducationalInstitutions = async () => {
      await store.dispatch('ppptcd/EDUCATIONAL_INSTITUTION_FIND_ALL', {
        limit: 500,
        query: '',
        page: 1,
        sortBy: 'name%7CASC',
        type: 'list',
      })
        .then(response => {
          if (response) {
            educationalInstitutions.value = response
          }
        })
    }

    const loadEducationalInstitutionDirectory = async () => {
      await store.dispatch('ppptcd/EDUCATIONAL_INSTITUTION_DIRECTORY_FIND_ALL', {
        idEducationalInstitution: educationalInstitutionSel.value.idEducationalInstitution,
        position: 'TUTOR',
        limit: 500,
        query: '',
        page: 1,
        sortBy: 'firstName%7CASC',
        type: 'list',
      })
        .then(response => {
          if (response) {
            directory.value = response
          }
        })
    }

    const saveForm = async () => {
      if (formData.value.firstName == null || formData.value.firstName === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar nombre del estudiante' } } })
        return
      }

      if (formData.value.lastNameOne == null || formData.value.lastNameOne === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar apellido paterno del estudiante' } } })
        return
      }

      if (formData.value.lastNameTwo == null || formData.value.lastNameTwo === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar apellido materno del estudiante' } } })
        return
      }

      if (dptoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar departamento' } } })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar provincia' } } })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar distrito' } } })
        return
      }

      if (educationalInstitutionSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Institución Educativa' } } })
        return
      }

      if (directorySel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar tutor' } } })
        return
      }

      let service = 'ppptcd/STUDENT_SAVE'
      if (props.formType === 'edit') {
        service = 'ppptcd/STUDENT_UPDATE'
      }

      formData.value.ubigeo = distSel.value
      formData.value.educationalInstitution = {
        idEducationalInstitution: educationalInstitutionSel.value.idEducationalInstitution,
      }
      formData.value.tutor = {
        idEducationalInstitutionDirectory: directorySel.value.idEducationalInstitutionDirectory,
      }
      formData.value.birthdate = moment(String(formData.value.birthdate), 'DD/MM/YYYY').format('YYYY-MM-DD')

      await store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('setting/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      loadEducationalInstitutions()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Caso de Consumo'
        await getUbigeos('dpto', '')
        await getUbigeos('prov', dptoSel.value.slice(0, 2))
      }
      if (props.formType === 'edit') {
        formData.value = {
          idStudent: props.dataEdit.idStudent,
          firstName: props.dataEdit.firstName,
          lastNameOne: props.dataEdit.lastNameOne,
          lastNameTwo: props.dataEdit.lastNameTwo,
          birthdate: props.dataEdit.birthdate,
          sex: props.dataEdit.sex,
          phone: props.dataEdit.phone,
          email: props.dataEdit.email,
          ubigeo: props.dataEdit.ubigeo,
          address: props.dataEdit.address,
          dniAttorney: props.dataEdit.dniAttorney,
          fistNameAttorney: props.dataEdit.fistNameAttorney,
          lastNameOneAttorney: props.dataEdit.lastNameOneAttorney,
          lastNameTwoAttorney: props.dataEdit.lastNameTwoAttorney,
          phoneAttorney: props.dataEdit.phoneAttorney,
          emailAttorney: props.dataEdit.emailAttorney,
          educationalInstitution: props.dataEdit.educationalInstitution,
          tutor: props.dataEdit.tutor,
          degree: props.dataEdit.degree,
          section: props.dataEdit.section,
          stateEvolution: props.dataEdit.stateEvolution,
        }
        dptoSel.value = `${props.dataEdit.ubigeo.slice(0, 2)}0000`
        await getUbigeos('dpto', '')
        await getUbigeos('prov', props.dataEdit.ubigeo.slice(0, 2))
        provSel.value = `${props.dataEdit.ubigeo.slice(0, 4)}00`
        await getUbigeos('dist', props.dataEdit.ubigeo.slice(0, 4))
        distSel.value = props.dataEdit.ubigeo
        educationalInstitutionSel.value = props.dataEdit.educationalInstitution
        directorySel.value = props.dataEdit.tutor
        titleForm.value = 'Editar Caso de Consumo'
      }
    }

    return {
      titleForm,
      isBusy,
      formData,
      saveForm,
      toggleSidebar,
      resetForm,
      configDP,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      gradosSecundaria,
      secciones,
      educationalInstitutions,
      educationalInstitutionSel,
      sexos,
      loadEducationalInstitutionDirectory,
      directory,
      directorySel,
      stages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
